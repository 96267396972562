import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { useSessionStore } from '@website/stores/sessionStore'

const NEXT_PUBLIC_GRAPHQL_API_ENDPOINT =
  process.env.NEXT_PUBLIC_GRAPHQL_API_ENDPOINT

if (!NEXT_PUBLIC_GRAPHQL_API_ENDPOINT) {
  throw new Error(
    'Environment variable NEXT_PUBLIC_GRAPHQL_API_ENDPOINT is not defined!',
  )
}

export const initializeApollo = () => {
  const authLink = setContext((operation, { headers }) => {
    const sessionData = useSessionStore.getState().sessionData
    const { browserFingerprint, sessionId, userAgent } = sessionData || {}

    return {
      headers: {
        ...headers,
        ...(browserFingerprint ? { 'X-Client-Udid': browserFingerprint } : {}),
        ...(sessionId ? { 'X-Session-Id': sessionId } : {}),
        ...(userAgent ? { 'User-Agent': userAgent } : {}),
      },
    }
  })

  const httpLink = new HttpLink({
    uri: NEXT_PUBLIC_GRAPHQL_API_ENDPOINT,
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        console.error(`[GraphQL error]: Message: ${message}`)
      })
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`)
    }
  })

  const link = ApolloLink.from([authLink, errorLink, httpLink])

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
    ssrMode: typeof window === 'undefined',
  })
}
