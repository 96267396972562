import { SessionData } from '@website/types'

export function adjustKeyLength(secretKey: string): Uint8Array {
  const encoder = new TextEncoder()
  let keyBytes = encoder.encode(secretKey)

  // Ensure key length is 32 bytes (256 bits) for AES-GCM
  if (keyBytes.length < 32) {
    // Pad key if it's too short
    const paddedKey = new Uint8Array(32)
    paddedKey.set(keyBytes)
    keyBytes = paddedKey
  } else if (keyBytes.length > 32) {
    // Trim key if it's too long
    keyBytes = keyBytes.slice(0, 32)
  }

  return keyBytes
}

async function sealDataWebCrypto(
  data: SessionData | Record<string, unknown>,
  secretKey: string,
) {
  const keyBytes = adjustKeyLength(secretKey)
  const key = await crypto.subtle.importKey('raw', keyBytes, 'AES-GCM', false, [
    'encrypt',
  ])

  // Generate a random IV
  const iv = crypto.getRandomValues(new Uint8Array(12))
  const encodedData = new TextEncoder().encode(JSON.stringify(data))
  const encryptedData = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    encodedData,
  )

  // Append IV to the encrypted data for later use in decryption
  const result = new Uint8Array(iv.byteLength + encryptedData.byteLength)
  result.set(iv, 0)
  result.set(new Uint8Array(encryptedData), iv.byteLength)

  // Return base64 encoded result
  return Buffer.from(result).toString('base64')
}

async function unsealDataWebCrypto(encrypted: string, secretKey: string) {
  const keyBytes = adjustKeyLength(secretKey)
  const encryptedBuffer = Buffer.from(encrypted, 'base64')
  const iv = encryptedBuffer.slice(0, 12)
  const data = encryptedBuffer.slice(12)

  const key = await crypto.subtle.importKey('raw', keyBytes, 'AES-GCM', false, [
    'decrypt',
  ])

  const decryptedData = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv },
    key,
    data,
  )
  return JSON.parse(new TextDecoder().decode(decryptedData))
}

export { sealDataWebCrypto, unsealDataWebCrypto }
