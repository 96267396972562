import React, { createContext, useContext, useEffect } from 'react'
import { SessionData } from '../types'
import { useSessionStore } from '../stores/sessionStore'

interface SessionContextType {
  sessionData: SessionData | null
}

const SessionContext = createContext<SessionContextType | undefined>(undefined)

export const SessionProvider: React.FC<{
  sessionData: SessionData | null
  children: React.ReactNode
}> = ({ sessionData: initialSessionData, children }) => {
  const { sessionData, setSessionData } = useSessionStore()

  // Initialize session data from initial props
  useEffect(() => {
    setSessionData(initialSessionData)
  }, [initialSessionData])

  // Fetch session data on client-side navigation
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const fetchSessionData = async () => {
        try {
          const response = await fetch('/api/session')
          if (response.ok) {
            const data: SessionData = await response.json()
            setSessionData(data)
          } else {
            console.error('Failed to fetch session data:', response.status)
          }
        } catch (error) {
          console.error('Error fetching session data:', error)
        }
      }

      fetchSessionData()
    }
  }, [setSessionData])

  return (
    <SessionContext.Provider value={{ sessionData }}>
      {children}
    </SessionContext.Provider>
  )
}

export const useSession = () => {
  const context = useContext(SessionContext)
  if (context === undefined) {
    throw new Error('useSession must be used within a SessionProvider')
  }
  return context.sessionData
}
