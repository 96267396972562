import { unsealDataWebCrypto } from '@website/utils/crypto'
import { sessionOptions } from '@website/lib/session'
import { parseCookies } from '@website/utils/cookies'
import { SessionData } from '@website/types'
import { IncomingMessage } from 'http'

export const getSessionData = async (
  req: IncomingMessage,
): Promise<SessionData | null> => {
  const cookies = req.headers.cookie
  if (cookies) {
    const sessionCookie = parseCookies(cookies)['session']
    if (sessionCookie) {
      try {
        const sessionData = await unsealDataWebCrypto(
          sessionCookie,
          sessionOptions.password as string,
        )
        return sessionData as SessionData
      } catch (error) {
        console.error('Failed to unseal session:', error)
      }
    }
  }
  return null
}
