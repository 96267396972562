export function parseCookies(cookieHeader: string): Record<string, string> {
  const cookies: Record<string, string> = {}
  cookieHeader.split(';').forEach((cookie) => {
    const [name, ...rest] = cookie.trim().split('=')
    const value = rest.join('=')
    if (name && value) {
      cookies[name] = value
    }
  })
  return cookies
}
