import { AppProps } from 'next/app'
import { NextPageContext } from 'next'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/next'
import { GoogleTagManager } from '@next/third-parties/google'
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client'
import { SessionProvider } from '@website/context/SessionContext'
import { EventTrackingProvider } from '@website/context/EventTrackingContext'
import { useApolloClient } from '@website/hooks/useApolloClient'
import { getSessionData } from '@website/utils/session'
import { SessionData } from '@website/types'
import { inter, montserrat } from '@website/styles/fonts'
import { ErrorBoundary } from '@ui/components/ErrorBoundary'

// Load Shared Tailwind CSS
import '@ui/styles/tailwind.css'

function Website({
  Component,
  pageProps,
}: AppProps & { sessionData: SessionData }) {
  const apolloClient = useApolloClient(pageProps.initialApolloState)

  return (
    <ErrorBoundary>
      <AppProviders pageProps={pageProps} apolloClient={apolloClient}>
        <Component {...pageProps} />
      </AppProviders>
    </ErrorBoundary>
  )
}

export interface PageProps {
  [key: string]: unknown
}

interface AppProvidersProps {
  children: React.ReactNode
  pageProps: PageProps
  apolloClient: ApolloClient<NormalizedCacheObject>
}

const AppProviders: React.FC<AppProvidersProps> = ({
  children,
  pageProps,
  apolloClient,
}) => {
  return (
    <>
      <div className={`${inter.variable} ${montserrat.variable} font-inter`}>
        <SessionProvider sessionData={pageProps.sessionData as SessionData}>
          <ApolloProvider client={apolloClient}>
            <EventTrackingProvider>{children}</EventTrackingProvider>
          </ApolloProvider>
        </SessionProvider>
      </div>
      <Analytics />
      <SpeedInsights />
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID || ''} />
    </>
  )
}

Website.getInitialProps = async ({ ctx }: { ctx: NextPageContext }) => {
  const sessionData = ctx.req ? await getSessionData(ctx.req) : null
  return {
    pageProps: {
      sessionData,
    },
  }
}

export default Website
