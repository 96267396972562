import { create } from 'zustand'
import { SessionData } from '@website/types'

interface SessionStore {
  sessionData: SessionData | null
  setSessionData: (data: SessionData | null) => void
}

export const useSessionStore = create<SessionStore>((set) => ({
  sessionData: null,
  setSessionData: (data) => set({ sessionData: data }),
}))
