import { SessionOptions } from 'iron-session'

export const THIRTY_DAYS = 60 * 60 * 24 * 30

export const sessionOptions: SessionOptions = {
  cookieName: '__session',
  password: process.env.SECRET_COOKIE_PASSWORD as string,
  ttl: THIRTY_DAYS,
  cookieOptions: {
    httpOnly: true,
    maxAge: THIRTY_DAYS,
    path: '/',
    sameSite: 'lax',
    secure: process.env.NODE_ENV === 'production',
  },
}
